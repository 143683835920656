import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/faces/mac_artist.jpg";

import singleCover1 from "assets/img/music/singlecover1.jpg";
import singleCover2 from "assets/img/music/togetherwestand.png";

import studio1 from "assets/img/music/studio1.jpg";
import studio2 from "assets/img/music/studio2.jpg";
import studio4 from "assets/img/music/studio4.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

import { brand } from "constants/config";
import { Album, Mic } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import studio20_1 from "../../assets/img/music/studio2020_1.jpeg";
import studio20_3 from "../../assets/img/music/studio2020_3.jpeg";
import studio20_2 from "../../assets/img/music/studio2020_2.jpeg";
import studio20_4 from "../../assets/img/music/studio2020_4.jpeg";
import ContactSection from "../LandingPage/Sections/ContactSection";

const useStyles = makeStyles(styles);

export default function MusicPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const { t } = useTranslation();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  return (
    <div>
      <Header
        color="transparent"
        brand={brand}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/music/studio2020_1.jpeg").default}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h1>{t("mixing.title")}</h1>
                    <h3 className={classes.title}>{t("mixing.teaser")}</h3>
                    <h5>{t("mixing.topic.statement")}</h5>
                    <h6>
                      {t("mixing.topic.part1")}
                      <br />
                      <br />
                      {t("mixing.topic.part2")}
                      <br />
                      <br />
                      {t("mixing.topic.part3.1")}
                      <a href="https://www.puremix.net/">Puremix</a>
                      {t("mixing.topic.part3.2")}
                      <a href="https://mixwiththemasters.com/">
                        Mix With The Masters
                      </a>
                      {t("mixing.topic.part3.3")}
                      <br />
                      <br />
                      {t("mixing.topic.part4")}
                      <br />
                      <br />
                    </h6>
                    <h6>{t("mixing.topic.requirements.title")}</h6>
                    <h5>
                      <lu>
                        <li>{t("mixing.topic.requirements.1")}</li>
                        <li>{t("mixing.topic.requirements.2")}</li>
                        <li>{t("mixing.topic.requirements.3")}</li>
                        <li>{t("mixing.topic.requirements.4")}</li>
                        <li>{t("mixing.topic.requirements.5.1")}</li>
                        <li>{t("mixing.topic.requirements.5.2")}</li>
                        <li>{t("mixing.topic.requirements.6")}</li>
                        <li>{t("mixing.topic.requirements.7")}</li>
                      </lu>
                      <br />
                    </h5>
                    <h6>{t("mixing.topic.newRequirements.title")}</h6>
                    <h5>
                      <lu>
                        <li>{t("mixing.topic.requirements.1")}</li>
                        <li>{t("mixing.topic.requirements.2")}</li>
                        <li>{t("mixing.topic.requirements.3")}</li>
                        <li>{t("mixing.topic.newRequirements.1")}</li>
                        <li>{t("mixing.topic.newRequirements.2")}</li>
                      </lu>
                      <br />
                    </h5>
                    <h6>{t("mixing.topic.newRequirements.trade")}</h6>
                    <h5>
                      <lu>
                        <li>{t("mixing.topic.requirements.4")}</li>
                        <li>{t("mixing.topic.requirements.5.1")}</li>
                        <li>{t("mixing.topic.requirements.5.2")}</li>
                        <li>{t("mixing.topic.requirements.6")}</li>
                        <li>{t("mixing.topic.requirements.7")}</li>
                      </lu>
                    </h5>
                    <h6>{t("mixing.topic.newRequirements.off")}</h6>
                    <h5>
                      <lu>
                        <li>{t("mixing.topic.newRequirements.1")}</li>
                        <li>{t("mixing.topic.newRequirements.2")}</li>
                      </lu>
                    </h5>
                    <h6>{t("mixing.topic.newRequirements.for")}</h6>
                    <br />
                    <h5>
                      {t("mixing.topic.part6.1")}{" "}
                      <a href="https://www.uaudio.de/blog/louis-cato-luna-session/">
                        {t("mixing.topic.part6.2")}
                      </a>
                    </h5>
                    <h6>
                      {t("mixing.topic.part7")}
                      <br />
                      <br />
                      <br />
                    </h6>
                    <br />
                    <br />
                  </div>
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                  <ContactSection purpose="mixing" />
                </div>
              </div>
            </GridContainer>

            <GridContainer justify="center">
              <NavPills
                alignCenter
                color="primary"
                tabs={[
                  {
                    tabButton: t("mixing.button0"),
                    tabIcon: Album,
                    tabContent: (
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={4}>
                          <a href="https://distrokid.com/hyperfollow/maciekniemczyk/saboteur-2">
                            <img
                              alt="Saboteur Single"
                              src={singleCover1}
                              className={navImageClasses}
                            />
                          </a>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <a href="https://www.youtube.com/embed/korlYgjNJ5g">
                            <img
                              alt="Together We Stand (Maciek Niemczyk Mix)"
                              src={singleCover2}
                              className={navImageClasses}
                            />
                          </a>
                        </GridItem>
                      </GridContainer>
                    )
                  }
                ]}
              />
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={3} sm={3} md={1}>
                <Tooltip
                  id="apple"
                  title={`${t("music.check")} Apple Music/iTunes`}
                  placement={window.innerWidth > 959 ? "top" : "left"}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    href="https://music.apple.com/de/artist/maciek-niemczyk/1493113770"
                    target="_blank"
                    color="transparent"
                    className={classes.navLink}
                  >
                    <i className={`${classes.socialIcons} fab fa-itunes`} />
                  </Button>
                </Tooltip>
              </GridItem>
              <GridItem xs={3} sm={3} md={1}>
                <Tooltip
                  id="spotify"
                  title={`${t("music.check")} Spotify`}
                  placement={window.innerWidth > 959 ? "top" : "left"}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    color="transparent"
                    href="https://open.spotify.com/artist/6x5TXAoLmhA7Fame7hQXTv?si=7dSsBvlMQpKjLFTrGoWtSQ"
                    target="_blank"
                    className={classes.navLink}
                  >
                    <i className={`${classes.socialIcons} fab fa-spotify`} />
                  </Button>
                </Tooltip>
              </GridItem>
              <GridItem xs={3} sm={3} md={1}>
                <Tooltip
                  id=" soundcloud"
                  title={`${t("music.check")} Soundcloud`}
                  placement={window.innerWidth > 959 ? "top" : "left"}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    color="transparent"
                    href="https://soundcloud.com/maciek-niemczyk"
                    target="_blank"
                    className={classes.navLink}
                  >
                    <i className={`${classes.socialIcons} fab fa-soundcloud`} />
                  </Button>
                </Tooltip>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Studio",
                      tabIcon: Mic,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={studio1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio20_1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio20_3}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={studio4}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio20_2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio20_4}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
