import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/faces/mac_artist.jpg";

import circleOfFifths1 from "assets/img/music/corcleoffifths.png";
import gatekeeperCover1 from "assets/img/music/gatekeeper.png";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

import { brand } from "constants/config";
import { Album } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function MusicPage(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  return (
    <div>
      <Header
        color="transparent"
        brand={brand}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/music/studio2020_4.jpeg").default}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>{t("sharing.title1")}</h3>
                    <h4>{t("sharing.description")}</h4>
                    <h6>
                      <a href="https://www.youtube.com/channel/UCIsV32uzRSKkNAuhStLj1vg/videos">
                        {t("sharing.part1")}
                      </a>
                    </h6>
                    <h5>{t("sharing.tipTitle")}</h5>
                    <h6>{t("sharing.tip1")}</h6>
                  </div>
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <NavPills
                alignCenter
                color="primary"
                tabs={[
                  {
                    tabButton: t("sharing.button0"),
                    tabIcon: Album,
                    tabContent: (
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={4}>
                          <a href="https://youtu.be/5zf3bgrygIA">
                            <img
                              alt="Sneak Preview into my new track and on my favourite plugin."
                              src={gatekeeperCover1}
                              className={navImageClasses}
                            />
                          </a>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <a href="https://www.youtube.com/embed/uajZIlIwmIo">
                            <img
                              alt="Dur -vs- Moll Pentatonik"
                              src={circleOfFifths1}
                              className={navImageClasses}
                            />
                          </a>
                        </GridItem>
                      </GridContainer>
                    )
                  }
                ]}
              />
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={3} sm={3} md={1}>
                <Tooltip
                  id="youtube"
                  title={`${t("sharing.check1")} YouTube ${t(
                    "sharing.check2"
                  )}`}
                  placement={window.innerWidth > 959 ? "top" : "left"}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    href="https://www.youtube.com/channel/UCIsV32uzRSKkNAuhStLj1vg/videos"
                    target="_blank"
                    color="transparent"
                    className={classes.navLink}
                  >
                    <i className={`${classes.socialIcons} fab fa-youtube`} />
                  </Button>
                </Tooltip>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={3} sm={3} md={1}></GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
