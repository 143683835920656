import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import * as emailjs from "emailjs-com";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "views/Components/SnackbarWrapper/SnackbarWrapper";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function ContactSection(props) {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [openFail, setOpenFail] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const { t } = useTranslation();

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(email).toLowerCase());
  }

  const handleSubmit = e => {
    e.preventDefault();
    if (!name | !email | !message) {
      setValidationMessage(t("contact.allFieldsRequired"));
      setOpenValidation(true);

      return;
    }

    if (!validateEmail(email)) {
      setValidationMessage(t("contact.invalidMailAddress"));
      setOpenValidation(true);

      return;
    }

    let templateParams = {
      from_name: name,
      from_mail: email,
      to_name: "Maciek",
      message_html: message,
      purpose: props.purpose
    };
    e.target.reset();
    console.log("about to submit: ", templateParams);
    emailjs
      .send(
        "gmail",
        "template_YJE5d22Y",
        templateParams,
        "user_xkOVM0jBJTTPGgXfJ6V1s"
      )
      .then(r => {
        console.log("submitted with result: ", r);
        if (r.status === 200) {
          resetForm();
          setOpenSuccess(true);
        } else {
          setOpenFail(true);
        }
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenFail(false);
    setOpenSuccess(false);
    setOpenValidation(false);
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setMessage("");
    setValidationMessage("");
  };

  let container = (
    <GridContainer justify="center">
      <GridItem cs={12} sm={12} md={8}>
        <h3 className={classes.title}>{t("contact.standard.title")}</h3>
        <h4 className={classes.description}>
          {t("contact.standard.part1")}
          <br />
          {t("contact.standard.part2")}
          <br />
          {t("contact.standard.part3")}
        </h4>
        <form onSubmit={e => handleSubmit(e)}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText={t("contact.standard.labelName")}
                id="name"
                type="text"
                name="name"
                value={name}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: e => setName(e.target.value)
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText={t("contact.standard.labelEmail")}
                id="email"
                type="text"
                name="email"
                value={email}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: e => setEmail(e.target.value)
                }}
              />
            </GridItem>
            <CustomInput
              labelText={t("contact.standard.labelMsg")}
              id="message"
              name="message"
              value={message}
              formControlProps={{
                fullWidth: true,
                className: classes.textArea
              }}
              inputProps={{
                onChange: e => setMessage(e.target.value),
                multiline: true,
                rows: 5
              }}
            />
            <GridItem xs={12} sm={12} md={6}>
              <Button color="primary" type="submit">
                {t("contact.standard.labelSubmit")}
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      </GridItem>
    </GridContainer>
  );

  if (props.purpose && props.purpose === "mixing") {
    container = (
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h3 className={classes.title}>{t("contact.contactTeaser")}</h3>
          <h4 className={classes.description}>
            <br />
            {t("mixing.topic.part8")}
            <br />
            {t("mixing.topic.part9")}
            <br />
            {t("contact.service.part1")}
            <br />
            <br />
          </h4>
          <form onSubmit={e => handleSubmit(e)}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText={t("contact.service.labelName")}
                  id="name"
                  type="text"
                  name="name"
                  value={name}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => setName(e.target.value)
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText={t("contact.service.labelEmail")}
                  id="email"
                  type="text"
                  name="email"
                  value={email}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => setEmail(e.target.value)
                  }}
                />
              </GridItem>
              <CustomInput
                labelText={t("contact.service.labelMsg")}
                id="message"
                name="message"
                value={message}
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea
                }}
                inputProps={{
                  onChange: e => setMessage(e.target.value),
                  multiline: true,
                  rows: 5
                }}
              />
              <GridItem xs={12} sm={12} md={6}>
                <Button color="primary" type="submit">
                  {t("contact.service.labelSubmit")}
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    );
  }

  return (
    <div className={classes.section}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={openSuccess}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant="success"
          message={t("contact.success")}
        />
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={openValidation}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant="info"
          message={validationMessage}
        />
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={openFail}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant="error"
          message={t("contact.failure")}
        />
      </Snackbar>
      {container}
    </div>
  );
}
