import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

// import profile from "assets/img/ai-based-app.png";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

import { brand } from "constants/config";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import LOIForm from "./fak10/LOI/LOIForm";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { ...rest } = props;

  return (
    <div>
      <Header
        color="transparent"
        brand={brand}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/ai-based-app.png").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            
            <GridContainer justify="center">
              <GridItem xs={18} sm={18} md={9}>
                <div>
                  <div>
                    <h1 className={classes.title}>{t("informatics.about.title")}</h1>
                    <h2>{t("informatics.about.teaser1")}</h2>
                    <h3>{t("informatics.about.teaser2")}</h3>
                    <h3 >{t("informatics.about.description1")}</h3>
                    <h4 >{t("informatics.about.description2")}</h4>
                    <h3>{t("informatics.benefits.consumer.title")}</h3>
                    <h6>
                      {t("informatics.benefits.consumer.1")}
                      <br />
                      <br />
                      {t("informatics.benefits.consumer.2")}
                      <br />
                      <br />
                      {t("informatics.benefits.consumer.3")}
                      <br />
                      <br />
                      {t("informatics.benefits.consumer.4")}
                      <br />
                      <br />
                    </h6>
                    <h3>{t("informatics.benefits.provider.title")}</h3>
                    <h6>
                      {t("informatics.benefits.provider.1")}
                      <br />
                      <br />
                      {t("informatics.benefits.provider.2")}
                      <br />
                      <br />
                      {t("informatics.benefits.provider.3")}
                      <br />
                      <br />
                      {t("informatics.benefits.provider.4")}
                      <br />
                      <br />
                    </h6>
                    <br />
                    <br />
                  </div>
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer >
              <GridItem xs={12} sm={12} md={8}>
                <LOIForm />
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>{t("informatics.part1")}</p>
            </div>
            <GridContainer justify="center">
              <GridItem xs={3} sm={3} md={1}>
                <Tooltip
                  id="linked-in"
                  title={`${t("informatics.check")} Linked-In`}
                  placement={window.innerWidth > 959 ? "top" : "left"}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    href="https://www.linkedin.com/in/maciejniemczyk/"
                    target="_blank"
                    color="transparent"
                    className={classes.navLink}
                  >
                    <i className={`${classes.socialIcons} fab fa-linkedin`} />
                  </Button>
                </Tooltip>
              </GridItem>
              <GridItem xs={3} sm={3} md={1}>
                <Tooltip
                  id=" rcs"
                  title={`${t("informatics.check")} Xing`}
                  placement={window.innerWidth > 959 ? "top" : "left"}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    color="transparent"
                    href="https://www.xing.com/profile/Maciek_Niemczyk"
                    target="_blank"
                    className={classes.navLink}
                  >
                    <i className={`${classes.socialIcons} fab fa-xing`} />
                  </Button>
                </Tooltip>
              </GridItem>
              <GridItem xs={3} sm={3} md={1}>
                <Tooltip
                  id=" rcs"
                  title={`${t("informatics.check")} Github`}
                  placement={window.innerWidth > 959 ? "top" : "left"}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    color="transparent"
                    href="https://github.com/Macilias"
                    target="_blank"
                    className={classes.navLink}
                  >
                    <i className={`${classes.socialIcons} fab fa-github`} />
                  </Button>
                </Tooltip>
              </GridItem>
              <GridItem xs={3} sm={3} md={1}>
                <Tooltip
                  id=" rcs"
                  title={`${t("informatics.check")} Stackoverflow`}
                  placement={window.innerWidth > 959 ? "top" : "left"}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    color="transparent"
                    href="https://stackoverflow.com/users/1195266/macilias"
                    target="_blank"
                    className={classes.navLink}
                  >
                    <i
                      className={`${classes.socialIcons} fab fa-stack-overflow`}
                    />
                  </Button>
                </Tooltip>
              </GridItem>
              <GridItem xs={3} sm={3} md={1}>
                <Tooltip
                  id=" rcs"
                  title={`${t("informatics.check")} Quora`}
                  placement={window.innerWidth > 959 ? "top" : "left"}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    color="transparent"
                    href="https://www.quora.com/profile/Maciek-Niemczyk"
                    target="_blank"
                    className={classes.navLink}
                  >
                    <i className={`${classes.socialIcons} fab fa-quora`} />
                  </Button>
                </Tooltip>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p></p>
              <br />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
