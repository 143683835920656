/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import {Link} from "react-router-dom";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import {Apps} from "@material-ui/icons";
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(styles);

export default function HeaderLinks() {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Menu"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/" className={classes.dropdownLink}>
              {t("menu.landing")}
            </Link>,
            <Link to="/music" className={classes.dropdownLink}>
              {t("menu.music")}
            </Link>,
            <Link to="/fak10" className={classes.dropdownLink}>
              {t("menu.fak10")}
            </Link>,
            <Link to="/mixing" className={classes.dropdownLink}>
              {t("menu.mixing")}
            </Link>,
            <Link to="/bmgpass" className={classes.dropdownLink}>
              {t("menu.sharing")}
            </Link>,
            <Link to="/imprint" className={classes.dropdownLink}>
              {t("menu.privacy")}
            </Link>,
          ]}
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-twitter"
          title="Follow on Twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{tooltip: classes.tooltip}}
        >
          <Button
            href="https://twitter.com/macilias"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-twitter"}/>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow on Facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{tooltip: classes.tooltip}}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/Maciek-Niemczyk-101426541394796/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"}/>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow on Instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{tooltip: classes.tooltip}}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/macilias/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"}/>
          </Button>
        </Tooltip>
      </ListItem>
      {/*<ListItem className={classes.listItem}>*/}
      {/*  <Tooltip*/}
      {/*    id="phone"*/}
      {/*    title="Rufen Sie uns direkt an"*/}
      {/*    placement={window.innerWidth > 959 ? "top" : "left"}*/}
      {/*    classes={{tooltip: classes.tooltip}}*/}
      {/*  >*/}
      {/*    <Button*/}
      {/*      href="tel:004915123055577"*/}
      {/*      target="_blank"*/}
      {/*      color="transparent"*/}
      {/*      className={classes.navLink}*/}
      {/*    >*/}
      {/*      <i className={classes.socialIcons + " fab fa-whatsapp"}/>*/}
      {/*    </Button>*/}
      {/*  </Tooltip>*/}
      {/*</ListItem>*/}
    </List>
  );
}
