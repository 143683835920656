import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import ServicesSection from "./Sections/ServicesSection.js";
import ContactSection from "./Sections/ContactSection.js";

import { brand } from "constants/config";
import { useTranslation } from "react-i18next";

// @material-ui/icons

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const { t } = useTranslation();

  return (
    <>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={brand}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/landing-bg.jpg").default}>
        <div className={classes.container}>
          <GridContainer justify="left">
            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>{t("landing.title1")}</h2>
              <br />
              <h6 className={classes.title}>{t("landing.title2")}</h6>
              <h5 className={classes.cursive}>{t("landing.quote1")}</h5>
              <h6 className={classes.title}>{t("landing.title3")}</h6>
              <br />
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
          <ServicesSection />
          <ContactSection purpose="general" />
        </div>
      </div>
      <Footer />
    </>
  );
}
