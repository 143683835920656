import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Route, Router, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.8.0";
// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import MusicPage from "views/MusicPage/MusicPage.js";
import MixingPage from "views/MixingPage/MixingPage.js";
import BMGPASSPage from "views/BMGPASSPage/BMGPASSPage.js";
import InformaticsPage from "views/InformaticsPage/InformaticsPage.js";
import LOIPage from "views/InformaticsPage/fak10/LOI/LOIForm";
// import PortfolioPage from "views/PortfolioPage/PortfolioPage.js";
// import LoginPage from "views/LoginPage/LoginPage.js";
import ImprintPage from "views/ImprintPage/ImprintPage";
// Initialize Firebase
import * as firebase from "firebase/app";
import "firebase/analytics";
// import i18n (needs to be bundled ;))
import "./i18n";

const firebaseConfig = {
  apiKey: "AIzaSyCXThG4lF30Shyd0mSHiisapZZarPMizN8",
  authDomain: "maciek-niemczyk.firebaseapp.com",
  databaseURL: "https://maciek-niemczyk.firebaseio.com",
  projectId: "maciek-niemczyk",
  storageBucket: "maciek-niemczyk.appspot.com",
  messagingSenderId: "1064652178177",
  appId: "1:1064652178177:web:d289608e79616f8cc25100",
  measurementId: "G-46RTSMK9YS"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

let hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/music" component={MusicPage} />
      <Route path="/mixing" component={MixingPage} />
      <Route path="/bmgpass" component={BMGPASSPage} />
      <Route path="/fak10" component={InformaticsPage} />
      <Route path="/loi" component={LOIPage} />
      <Route path="/imprint" component={ImprintPage} />
      {/*<Route path="/login" component={LoginPage}/>*/}
      <Route path="/" component={LandingPage} />
    </Switch>
  </Router>,

  document.getElementById("root")
);
