import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/serviceStyle.js";

import music from "assets/img/mesa20.png";
import mixing from "assets/img/studio20.png";
import bmgpass from "assets/img/pedalboard20.png";
import informatics from "assets/img/ai-based-app.png";
import { useTranslation } from "react-i18next";

// @material-ui/icons

const useStyles = makeStyles(styles);

export default function ServicesSection() {
  const classes = useStyles();
  const { t } = useTranslation();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <h3 className={classes.title}>{t("services.title1")}</h3>
          <h5 className={classes.description}>{t("services.description")}</h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card plain>
            <a href="/music">
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={music} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                {t("services.rightShort")}
                <br />
                <small className={classes.smallTitle}>
                  {t("services.rightTitle")}
                </small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  {t("services.rightDescription")}
                </p>
              </CardBody>
            </a>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card plain>
            <a href="/mixing">
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={mixing} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                {t("services.halfRightShort")}
                <br />
                <small className={classes.smallTitle}>
                  {t("services.halfRightTitle")}
                </small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  {t("services.halfRightDescription")}
                </p>
              </CardBody>
            </a>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card plain>
            <a href="/bmgpass">
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={bmgpass} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                {t("services.halfLeftShort")}
                <br />
                <small className={classes.smallTitle}>
                  {t("services.halfLeftTitle")}
                </small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  {t("services.halfLeftDescription")}
                </p>
              </CardBody>
            </a>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card plain>
            <a href="/fak10">
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={informatics} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                {t("services.leftShort")}
                <br />
                <small className={classes.smallTitle}>
                  {t("services.leftTitle")}
                </small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  {t("services.leftDescription")}
                </p>
              </CardBody>
            </a>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
