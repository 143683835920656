import * as React from "react";

import { IValues } from "./Form";

export interface IBlockContext extends IBLockState {
  /* Function that allows values in the values state to be set */
  setValues: (values: IValues) => void;
}

/*
 * The context which allows state and functions to be shared with Field.
 */
// @ts-ignore
const dummyBlockContext: IBlockContext = {
  setValues: function (p1: IValues) {
  },
};
export const BlockContext = React.createContext<IBlockContext>(dummyBlockContext);

export interface IBlockProps {
  /* The unique block name */
  id: string;

  /* The label text for the block */
  label?: string;

  /* A prop which allows content to be injected */
  render: () => React.ReactNode;
}

export interface IBLockState {
  /* The field values */
  values: IValues;
}

export class Block extends React.Component<IBlockProps, IBLockState> {
  constructor(props: IBlockProps) {
    super(props);

    const values: IValues = {};
    this.state = {
      values
    };
  }

  /**
   * Stores new field values in state
   * @param {IValues} values - The new field values
   */
  private setValues = (values: IValues) => {
    this.setState({values: {...this.state.values, ...values}});
  };


  public render() {
    const context: IBlockContext = {
      ...this.state,
      setValues: this.setValues,
    };

    return (
      <BlockContext.Provider value={context}>
        <div className="jumbotron">
          {this.props.label && <label htmlFor={this.props.id}>{this.props.label}</label>}
          {this.props.render()}
        </div>
      </BlockContext.Provider>
    );
  }
}
