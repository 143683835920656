import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/faces/mac_artist.jpg";

import singleCover1 from "assets/img/music/singlecover1.jpg";
import liveActCover1 from "assets/img/music/liveactcover1.jpg";

import studio1 from "assets/img/music/studio1.jpg";
import studio2 from "assets/img/music/studio2.jpg";
import studio4 from "assets/img/music/studio4.jpg";
import studio20_1 from "assets/img/music/studio2020_1.jpeg";
import studio20_2 from "assets/img/music/studio2020_2.jpeg";
import studio20_3 from "assets/img/music/studio2020_3.jpeg";
import studio20_4 from "assets/img/music/studio2020_4.jpeg";

import gig1 from "assets/img/music/gig1.jpg";
import gig2 from "assets/img/music/gig2.jpg";
import gig3 from "assets/img/music/gig3.jpg";
import gig4 from "assets/img/music/gig4.jpg";
import gig5 from "assets/img/music/gig5.jpg";
import gig6 from "assets/img/music/gig6.jpg";
import gig7 from "assets/img/music/gig7.jpg";
import gig8 from "assets/img/music/gig8.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

import { brand } from "constants/config";
import { Album, Mic, Person } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import singleCover2 from "../../assets/img/music/togetherwestand.png";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function MusicPage(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  return (
    <div>
      <Header
        color="transparent"
        brand={brand}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/music/studio2020_2.jpeg").default}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>{t("music.title1")}</h3>
                    <h6>
                      {t("music.part1")}
                      <br />
                      {t("music.part2")}
                      <br />
                      {t("music.part3")}
                      <br />
                      {t("music.part4")}
                      <br />
                      {t("music.part5")}
                    </h6>
                  </div>
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <NavPills
                alignCenter
                color="primary"
                tabs={[
                  {
                    tabButton: t("music.button0"),
                    tabIcon: Album,
                    tabContent: (
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={4}>
                          <a href="https://distrokid.com/hyperfollow/maciekniemczyk/saboteur-2">
                            <img
                              alt="Saboteur Single"
                              src={singleCover1}
                              className={navImageClasses}
                            />
                          </a>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <a href="https://youtu.be/4FwdGOgoahs">
                            <img
                              alt="T.R.I.P GoaBlues LiveAct"
                              src={liveActCover1}
                              className={navImageClasses}
                            />
                          </a>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <a href="https://www.youtube.com/embed/korlYgjNJ5g">
                            <img
                              alt="Together We Stand (Maciek Niemczyk Mix)"
                              src={singleCover2}
                              className={navImageClasses}
                            />
                          </a>
                        </GridItem>
                      </GridContainer>
                    )
                  }
                ]}
              />
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={3} sm={3} md={1}>
                <Tooltip
                  id="apple"
                  title={`${t("music.check")} Apple Music/iTunes`}
                  placement={window.innerWidth > 959 ? "top" : "left"}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    href="https://music.apple.com/de/artist/maciek-niemczyk/1493113770"
                    target="_blank"
                    color="transparent"
                    className={classes.navLink}
                  >
                    <i className={`${classes.socialIcons} fab fa-itunes`} />
                  </Button>
                </Tooltip>
              </GridItem>
              <GridItem xs={3} sm={3} md={1}>
                <Tooltip
                  id="spotify"
                  title={`${t("music.check")} Spotify`}
                  placement={window.innerWidth > 959 ? "top" : "left"}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    color="transparent"
                    href="https://open.spotify.com/artist/6x5TXAoLmhA7Fame7hQXTv?si=7dSsBvlMQpKjLFTrGoWtSQ"
                    target="_blank"
                    className={classes.navLink}
                  >
                    <i className={`${classes.socialIcons} fab fa-spotify`} />
                  </Button>
                </Tooltip>
              </GridItem>
              <GridItem xs={3} sm={3} md={1}>
                <Tooltip
                  id="soundcloud"
                  title={`${t("music.check")} Soundcloud`}
                  placement={window.innerWidth > 959 ? "top" : "left"}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    color="transparent"
                    href="https://soundcloud.com/maciek-niemczyk"
                    target="_blank"
                    className={classes.navLink}
                  >
                    <i className={`${classes.socialIcons} fab fa-soundcloud`} />
                  </Button>
                </Tooltip>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: t("music.button1"),
                      tabIcon: Favorite,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={studio4}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={gig1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={gig4}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={gig2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={gig7}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    },
                    {
                      tabButton: t("music.button2"),
                      tabIcon: Mic,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={studio1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio20_1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio20_3}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={studio4}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio20_2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio20_4}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    },
                    {
                      tabButton: t("music.button3"),
                      tabIcon: Person,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={gig1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={gig2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={gig3}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={gig4}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={gig5}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={gig6}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={gig7}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={gig8}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
