import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import Card from "../../../components/Card/Card";
import GridItem from "../../../components/Grid/GridItem";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card plain>
            <GridItem xs={12} sm={12} md={3} className={classes.itemGrid}>
              <h4 className={classes.cardTitle}>{t("product.title1")}</h4>
              <iframe
                title="Gatekeeper"
                width="250px"
                height="250px"
                src="https://www.youtube.com/embed/5zf3bgrygIA"
                frameBorder="0"
                allowFullScreen
              ></iframe>
              <iframe
                title="Saboteur"
                width="250px"
                height="250px"
                src="https://www.youtube.com/embed/1roJfF0yfKk"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </GridItem>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
