import * as React from "react";
import { IFieldProps } from "./Field";
import * as emailjs from "emailjs-com";

export interface IFormContext extends IFormState {
  /* Function that allows values in the values state to be set */
  setValues: (values: IValues) => void;
}

/*
 * The context which allows state and functions to be shared with Field.
 */
// @ts-ignore
const dummyFormContext: IFormContext = {
  setValues: function (p1: IValues) { },
};
export const FormContext = React.createContext<IFormContext>(dummyFormContext);

export interface IFields {
  [key: string]: IFieldProps;
}

interface IFormProps {

  /* The props for all the fields on the form */
  fields?: IFields;

  /* A prop which allows content to be injected */
  render: () => React.ReactNode;
  clear: () => void;
  trim: () => string | null;
}

export interface IValues {
  /* Key value pairs for all the field values with key being the field name */
  [key: string]: any;
}

export interface IFormState {
  /* The field values */
  values: IValues;
  trimmedDataURL: string;

  /* Whether the form has been successfully submitted */
  submitSuccess?: boolean;
}

export class Form extends React.Component<IFormProps, IFormState> {
  constructor(props: IFormProps) {
    super(props);

    const values: IValues = {};
    this.state = {
      values,
      trimmedDataURL: '',
    };
  }
  /**
   * Stores new field values in state
   * @param {IValues} values - The new field values
   */
  private setValues = (values: IValues) => {
    this.setState({ values: { ...this.state.values, ...values } });
  };


  /**
   * Handles form submission
   * @param {React.FormEvent<HTMLFormElement>} e - The form event
   */
  private handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();

    this.submitForm();
  };

  /**
   * 1. Prints the form as json to console
   * 2. Generates a json file for download
   * @returns {void}
   */
  private submitForm(): void {
    const trimmedDataURL = this.props.trim();
    if (trimmedDataURL) {
      this.setState({ trimmedDataURL: trimmedDataURL });
    }
    let jsonResult = JSON.stringify({ "formData": this.state.values, "signature": trimmedDataURL });
    // basic requirement: print form as json to console
    console.log(jsonResult);
    let templateParams = {
      from_name: name,
      from_mail: "checkForm",
      to_name: "Maciek",
      message_html: jsonResult,
      purpose: "fak10 LOI"
    };
    console.log("about to submit: ", templateParams);
    emailjs
      .send(
        "gmail",
        "template_YJE5d22Y",
        templateParams,
        "user_xkOVM0jBJTTPGgXfJ6V1s"
      )
      .then(r => {
        console.log("submitted with result: ", JSON.stringify(r));
        if (r.status === 200) {
          this.setState({ values: {} });
          this.setState({ submitSuccess: true })
        } else {
          this.setState({ submitSuccess: false })
        }
      });

  }

  public render() {
    const { submitSuccess } = this.state;
    const context: IFormContext = {
      ...this.state,
      setValues: this.setValues,
    };

    return (
      <FormContext.Provider value={context}>
        <form onSubmit={this.handleSubmit} noValidate={true}>
          <div className="container">
            {/* render blocks and fields */}
            {this.props.render()}
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
            {submitSuccess && (
              <div className="alert alert-info" role="alert">
                The form was successfully submitted!
              </div>
            )}
          </div>
        </form>
      </FormContext.Provider>
    );
  }
}