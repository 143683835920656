/* eslint-disable no-undef */
import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import "./index.css";
import 'bootstrap/dist/css/bootstrap.css';
import { Form } from "./Form";
import { Block } from "./Block";
import { Field } from "./Field";
import { useTranslation } from "react-i18next";

export const RegistrationForm: React.FunctionComponent = () => {
  const [count, setCount] = useState(1);
  const sigPad = useRef<SignatureCanvas | null>(null);
  const { t } = useTranslation();

  const clear = () => {
    if (sigPad.current) {
      sigPad.current.clear();
    }
  }
  const trim = (): string | null => {
    if (sigPad.current) {
      return sigPad.current.getTrimmedCanvas()
        .toDataURL('image/png');
    }
    return null;
  }

  const personBlocks: Array<JSX.Element> = [];
  for (let i = 1; i < count + 1; i++) {
    personBlocks.push(
      <Block
        id={"person" + i}
        label={t("informatics.loi.person.title") + " " + i}
        key={i}
        render={() => (
          <React.Fragment>
            <Field
              id={"person" + i + "_firstName"}
              label={t("informatics.loi.person.firstName")}
            />
            <Field
              id={"person" + i + "_lastName"}
              label={t("informatics.loi.person.lastName")}
            />
            <Field
              id={"person" + i + "_companyName"}
              label={t("informatics.loi.person.companyName")}
            />
            <Field
              id={"person" + i + "_email"}
              label={t("informatics.loi.person.email")}
            />
            <Field
              id={"person" + i + "_phone"}
              label={t("informatics.loi.person.phone")}
            />
            <Field
              id={"person" + i + "_gender"}
              label={t("informatics.loi.person.gender")}
              editor="dropdown"
              options={[
                "",
                "male",
                "female",
                "diverse"
              ]}
            />
          </React.Fragment>
        )}
      />
    );
  }

  const personButton: JSX.Element = (
    <div>
      <button
        id="addButton"
        className="btn btn-primary"
        onClick={e => {
          e.preventDefault();
          setCount(count + 1);
        }}
      >
        {t("informatics.loi.button.addPerson")}
      </button>
    </div>
  );

  let curr = new Date();
  let date = curr.toISOString().substr(0, 10);

  const Signature: JSX.Element = (
    <div className="jumbotron">
      <label htmlFor="signature">{t("informatics.loi.signature.title")}</label>
      <div id="signature">
        <SignatureCanvas
          penColor="blue"
          canvasProps={{ width: 1000, height: 200, className: "sigCanvas" }}
          ref={(ref) => { sigPad.current = ref }}
        />
      </div>
      <label htmlFor="city">{t("informatics.loi.signature.city")}</label>
      <input
        id="city"
        type="text"
        defaultValue="Hamburg"
        className="form-control"
      />
      <label htmlFor="date">{t("informatics.loi.signature.date")}</label>
      <input
        id="date"
        type="date"
        name="date"
        defaultValue={date}
        className="form-control"
      />
    </div>
  );

  return (
    <Form
      clear={clear}
      trim={trim}
      render={() => (
        <React.Fragment>
          <div className="alert alert-info" role="alert">
            {t("informatics.loi.title")}
          </div>
          <Block
            id="movingFrom"
            label={t("informatics.loi.intent.title")}
            render={() => (
              <React.Fragment>
                <Field
                  editor="date"
                  id="interviewingDate"
                  label={t("informatics.loi.intent.date")} />
                <Field
                  editor="textbox"
                  id="impression"
                  label={t("informatics.loi.intent.impression")}
                />
                <Field
                  editor="textbox"
                  id="fromAddress"
                  label={t("informatics.loi.intent.place")}
                />
                <Field
                  editor="textbox"
                  id="valueIntent"
                  label={t("informatics.loi.intent.value")}
                />
              </React.Fragment>
            )}
          />

          {personBlocks.map((value, index) => {
            return <div key={"pers" + (index + 1)}>{value}</div>;
          })}
          {personButton}
          <br></br>
          {Signature}
        </React.Fragment>
      )}
    />
  );
};

function LOIForm() {
  return (
    <div className="mt-3">
      <RegistrationForm />
    </div>
  );
}

export default LOIForm;
