import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/imprintPage.js";

const useStyles = makeStyles(styles);

export default function ImprintPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Header
        color="transparent"
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/landing-bg.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="flex-start">
              <GridItem xs={12} sm={12} md={12} className={classes.navWrapper}>
                <h1>Legal Disclosure</h1>
                <br />
                Internet address:{" "}
                <a href="www.maciek-niemczyk.de" target="_blank">
                  www.maciek-niemczyk.de
                </a>
                <br />
                <h2>Disclaimer</h2>
                <h3>Accountability for content</h3>
                The contents of our pages have been created with the utmost
                care. However, we cannot guarantee the contents' accuracy,
                completeness or topicality. According to statutory provisions,
                we are furthermore responsible for our own content on these web
                pages. In this matter, please note that we are not obliged to
                monitor the transmitted or saved information of third parties,
                or investigate circumstances pointing to illegal activity. Our
                obligations to remove or block the use of information under
                generally applicable laws remain unaffected by this as per §§ 8
                to 10 of the Telemedia Act (TMG).
                <br />
                <h3>Accountability for links</h3>
                Responsibility for the content of external links (to web pages
                of third parties) lies solely with the operators of the linked
                pages. No violations were evident to us at the time of linking.
                Should any legal infringement become known to us, we will remove
                the respective link immediately.
                <h3>Copyright</h3>
                Our web pages and their contents are subject to German copyright
                law. Unless expressly permitted by law, every form of utilizing,
                reproducing or processing works subject to copyright protection
                on our web pages requires the prior consent of the respective
                owner of the rights. Individual reproductions of a work are only
                allowed for private use. The materials from these pages are
                copyrighted and any unauthorized use may violate copyright laws.
                <br />
                <i>Source:</i>
                <a href="http://www.translate-24h.de" rel="noopener">
                  Englisch-Übersetzung translate-24h.de
                </a>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
